import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Layout from "../components/layout"

interface FileNode {
  relativePath: string
  prettySize: string
  extension: string
  birthTime: string
}

interface FileInfo {
  node: FileNode
}

export default () => {
  const data = useStaticQuery(graphql`
    query {
      allFile {
        edges {
          node {
            relativePath
            prettySize
            extension
            birthTime(fromNow: true)
          }
        }
      }
    }
  `)
  // tslint:disable-next-line:no-console
  return (
    <Layout>
      <h1>My projects</h1>
      <table>
        <thead>
          <tr>
            <th>Path</th>
            <th>Size</th>
            <th>Extension</th>
            <th>Created</th>
          </tr>
        </thead>
        <tbody>
          {data.allFile.edges.map((info: FileInfo, index: number) => (
            <tr key={index}>
              <td>{info.node.relativePath}</td>
              <td>{info.node.prettySize}</td>
              <td>{info.node.extension}</td>
              <td>{info.node.birthTime}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Layout>
  )
}
